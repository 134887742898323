import { coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatSliderModule } from '@angular/material/slider';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { SimpleChangesTyped, Vector3 } from '@trimble-gcs/common';
import {
  ModusButtonModule,
  ModusCheckboxModule,
  ModusFormFieldModule,
  ModusIconModule,
  ModusInputModule,
  ModusSelectModule,
  ModusTooltipModule,
  isDefined,
  isNil,
} from '@trimble-gcs/modus';
import { distinctUntilChanged, filter, finalize, forkJoin, map } from 'rxjs';
import { Camera, CameraChangedEventArgument, CameraMode } from 'trimble-connect-workspace-api';
import { Alignment, PointCoordinate } from '../../api';
import { UomPipe } from '../../pipes';
import { BusyIndicatorComponent, ReadonlyLookup, degToRad, round } from '../../shared';
import {
  AlignmentCameraMode,
  AppState,
  NavigationArgs,
  NavigationService,
  StationPositions,
  defaultNavigationArgs,
} from '../../state';

interface AlignmentCamera {
  mode: AlignmentCameraMode;
  label: string;
  icon: string;
  tooltipClass?: string;
  connectCameraMode: CameraMode;
  step: number;
}

const AlignmentCameras: ReadonlyLookup<AlignmentCameraMode, AlignmentCamera> = {
  Free: {
    mode: 'Free',
    label: 'Free view',
    icon: 'cube',
    connectCameraMode: CameraMode.Rotate,
    step: 1,
  },
  Trolley: {
    mode: 'Trolley',
    label: 'Trolley view',
    icon: 'video',
    connectCameraMode: CameraMode.LookAround,
    step: 0.5,
  },
  Attached: {
    mode: 'Attached',
    label: 'Attached view',
    icon: 'pan',
    tooltipClass: 'arrow-right',
    connectCameraMode: CameraMode.Rotate,
    step: 0.5,
  },
};

interface CameraUpdate {
  position: PointCoordinate;
  lookAt: PointCoordinate;
  yaw: number;
  pitch: number;
  yawOffset?: number;
  pitchOffset?: number;
  station: number;
}

interface FormNavigationArgs {
  cameraHeight: number | null;
  cameraDistance: number | null;
  cameraOffset: number | null;
  chainage: number | null;
  stationIndex: number | null;
  reverse: boolean;
  lockLookAt: boolean;
}

@UntilDestroy()
@Component({
  selector: 'nzc-alignment-navigation',
  standalone: true,
  imports: [
    MatSliderModule,
    MatMenuModule,
    MatButtonToggleModule,
    ModusButtonModule,
    ModusCheckboxModule,
    ModusFormFieldModule,
    ModusIconModule,
    ModusInputModule,
    ModusSelectModule,
    ModusTooltipModule,
    ReactiveFormsModule,
    BusyIndicatorComponent,
    UomPipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './alignment-navigation.component.html',
  styleUrls: ['./alignment-navigation.component.scss'],
})
export class AlignmentNavigationComponent implements OnInit, OnChanges, OnDestroy {
  @HostBinding('class') componentClasses = 'flex flex-col grow';

  @Input() public alignment?: Alignment;

  private readonly workspace = this.store.selectSnapshot(AppState.workspace);
  private readonly cameraHeightOffset = 2; // Camera offset obove height ("eye position")
  public readonly jumpIncrement = 1;

  private lastCameraUpdate?: CameraUpdate;
  private initialized = false;
  private navigationArgs?: NavigationArgs;

  public readonly AlignmentCameras = AlignmentCameras;
  public readonly cameras = Object.values(AlignmentCameras);
  public selectedCamera = AlignmentCameras[defaultNavigationArgs.cameraMode];
  public sliderStart = 0;
  public sliderEnd = 0;
  public stationPositions?: StationPositions;
  public loading = false;

  public readonly formGroup = new FormGroup({
    cameraHeight: new FormControl<number | null>(null),
    cameraDistance: new FormControl<number | null>(null),
    cameraOffset: new FormControl<number | null>(null),
    chainage: new FormControl<number | null>(null, {
      validators: Validators.required,
      updateOn: 'blur',
    }),
    stationIndex: new FormControl<number | null>(null),
    reverse: new FormControl<boolean>(defaultNavigationArgs.reverse, { nonNullable: true }),
    lockLookAt: new FormControl<boolean>(defaultNavigationArgs.lockLookAt, { nonNullable: true }),
  });

  public cameraControl = new FormControl<AlignmentCamera>(this.selectedCamera, {
    nonNullable: true,
  });

  constructor(
    private store: Store,
    private navigationService: NavigationService,
  ) {
    this.formGroup.controls.chainage.valueChanges
      .pipe(
        untilDestroyed(this),
        filter(() => this.initialized),
        distinctUntilChanged(),
      )
      .subscribe((chainage) => this.chainageChanged(coerceNumberProperty(chainage, null)));

    this.formGroup.controls.lockLookAt.valueChanges
      .pipe(
        untilDestroyed(this),
        filter(() => this.initialized),
        distinctUntilChanged(),
      )
      .subscribe((lockLookAt) =>
        this.updateLookAtOffsetsFromCamera(coerceBooleanProperty(lockLookAt)),
      );

    this.cameraControl.valueChanges
      .pipe(
        untilDestroyed(this),
        filter(() => this.initialized),
        distinctUntilChanged(),
      )
      .subscribe((camera) => this.selectCamera(camera));

    this.formGroup.valueChanges
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged(),
        // distinctUntilPropertiesChanged(), // Compare properties as form changes are always new objects
      )
      .subscribe((navArgs) => {
        // console.log('Navigation args changed', navArgs);
        this.navigationChanged(navArgs);
      });
  }

  public ngOnInit(): void {
    // Listen to model-reset event
    this.workspace.event$
      .pipe(
        untilDestroyed(this),
        filter((event) => event.id === 'viewer.onModelReset'),
      )
      .subscribe(() => {
        this.cameraControl.setValue(AlignmentCameras.Free);
      });

    // Listen to camera changes
    this.workspace.event$
      .pipe(
        untilDestroyed(this),
        filter((event) => event.id === 'viewer.onCameraChanged'),
        map((event) => event.arg as CameraChangedEventArgument),
      )
      .subscribe((cameraChange) => {
        // Check camera projection changes
        if (cameraChange.data.projectionType !== 'perspective') {
          this.cameraControl.setValue(AlignmentCameras.Free);
          return;
        }

        // Check camera position changes
        if (this.hasCameraPositionChanged(cameraChange.data)) {
          // console.log('Camera position changed in viewer', {
          //   camera: cameraChange.data,
          //   lastUpdate: this.lastCameraUpdate,
          // });

          if (this.selectedCamera === AlignmentCameras.Attached) {
            // Attached-view, calculate offsets from current camera position
            this.updateOffsetsFromCamera();
          } else {
            // Else change to free mode
            this.cameraControl.setValue(AlignmentCameras.Free);
          }

          return;
        }

        // Check camera look-at changes
        if (this.hasCameraLookAtChanged(cameraChange.data)) {
          // console.log('Camera look-at changed in viewer', {
          //   camera: cameraChange.data,
          //   lastUpdate: this.lastCameraUpdate,
          // });

          this.updateLookAtOffsetsFromCamera(true);
        }
      });
  }

  public ngOnChanges(changes: SimpleChangesTyped<AlignmentNavigationComponent>): void {
    // Reset on any change
    this.reset();
  }

  public ngOnDestroy(): void {
    this.workspace.removeLookAtMarker();
  }

  @HostListener('wheel', ['$event'])
  public onMouseWheel(event: WheelEvent) {
    // Ignore when free view is selected
    if (this.selectedCamera === AlignmentCameras.Free) {
      return;
    }

    const direction = this.formGroup.value.reverse ? -1 : 1;
    const jumpDirection = Math.sign(event.deltaY) * -1 * direction;
    const currentStation = this.formGroup.value.stationIndex ?? 0;
    const nextStation = currentStation + this.jumpIncrement * jumpDirection;

    // Ensure next station is within range
    if (nextStation >= 0 && nextStation < this.sliderEnd) {
      this.formGroup.patchValue({
        stationIndex: nextStation,
      });
    }
  }

  private reset(): void {
    this.workspace.removeLookAtMarker();
    this.lastCameraUpdate = undefined;
    this.stationPositions = undefined;
    this.sliderEnd = 0;
    this.initialized = false;

    // Initialize navigation
    this.initNavigation();
  }

  private initNavigation(): void {
    // Ensure required values are set
    if (isNil(this.alignment)) {
      return;
    }

    this.loading = true;
    forkJoin({
      stationPositions: this.navigationService.getStationPositions(this.alignment.id),
      navigationArgs: this.navigationService.getNavigationArgs(this.alignment.id),
    })
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.loading = false)),
      )
      .subscribe((result) => {
        this.stationPositions = result.stationPositions;
        this.navigationArgs = result.navigationArgs;

        // Update slider range and select station index
        this.sliderEnd = this.stationPositions.values.length - 1;

        // Update min/max validators on chainage
        const startChainage = this.stationPositions.xAxis.start;
        const endChainage =
          startChainage + this.stationPositions.values[this.stationPositions.values.length - 1][0];
        this.formGroup.controls.chainage.setValidators([
          Validators.required,
          Validators.min(startChainage),
          Validators.max(endChainage),
        ]);

        // Init form (controls) to previous values
        const navArgs = this.navigationArgs;
        this.selectedCamera = AlignmentCameras[navArgs.cameraMode];
        this.cameraControl.setValue(this.selectedCamera);
        this.formGroup.patchValue({
          cameraHeight: navArgs.cameraHeight,
          cameraDistance: navArgs.cameraDistance,
          cameraOffset: navArgs.cameraOffset,
          stationIndex: this.findStationIndex(navArgs.chainage),
          reverse: navArgs.reverse,
          lockLookAt: navArgs.lockLookAt,
        });

        // Set as initialed
        this.initialized = true;
      });
  }

  private findStationIndex(chainage: number | undefined | null): number {
    // Ensure required values are set
    if (isNil(this.stationPositions) || isNil(chainage)) {
      return 0;
    }

    // Find first station with chainage greater than or equal to specified chainage
    const chainageStart = this.stationPositions.xAxis.start;
    let stationIndex = this.stationPositions.values.findIndex((values) => {
      const offset = values[0];
      const stationChainage = chainageStart + offset;
      return stationChainage > chainage;
    });

    // Use station before matching station (as we search for station >= chainage)
    stationIndex = Math.max(0, stationIndex - 1);

    return stationIndex;
  }

  private chainageChanged(chainage: number | null): void {
    const stationIndex = this.findStationIndex(chainage);
    this.formGroup.patchValue({
      stationIndex: stationIndex,
    });
  }

  private async navigationChanged(navigationArgs: Partial<FormNavigationArgs>): Promise<void> {
    // Ensure required values are set
    if (
      isNil(this.alignment) ||
      isNil(this.stationPositions) ||
      isNil(navigationArgs.stationIndex) ||
      isNil(navigationArgs.cameraHeight) ||
      isNil(navigationArgs.cameraDistance) ||
      isNil(navigationArgs.cameraOffset) ||
      this.selectedCamera === AlignmentCameras.Free // Free view, do not update camera
    ) {
      return;
    }

    // Determine direction multiplier
    const direction = navigationArgs.reverse ? 1 : -1;

    // Get station position and direction vectors
    const stationCoordinate = this.stationPositions.coordinates[navigationArgs.stationIndex];
    const stationDirections = this.stationPositions.directions[navigationArgs.stationIndex];
    const stationPos = Vector3.fromCoordinate(stationCoordinate);
    const stationForward = Vector3.fromCoordinate(stationDirections.forward);
    const stationUp = Vector3.fromCoordinate(stationDirections.up);
    const stationRight = Vector3.fromCoordinate(stationDirections.right);

    let cameraPosition: Vector3 | undefined;
    let lookAtPosition: Vector3 | undefined;
    switch (this.selectedCamera) {
      case AlignmentCameras.Trolley:
        // Calculate camera position as a specific distance from the station position,
        // in the reverse direction of the station's forward direction vector.
        // Additionally add a vertical/height offset
        cameraPosition = stationPos
          .add(stationForward.multiplyScalar(navigationArgs.cameraDistance * direction))
          .add(stationUp.multiplyScalar(navigationArgs.cameraHeight + this.cameraHeightOffset));

        // Calculate look-at position as at the station position, with a vertical/height offset
        lookAtPosition = stationPos.add(stationUp.multiplyScalar(navigationArgs.cameraHeight));

        break;

      case AlignmentCameras.Attached:
        // Calculate camera position as a specific distance from the station position,
        // in the reverse direction of the station's forward direction vector.
        // Additionally add a vertical/height offset, and a horizontal/lateral offset
        cameraPosition = stationPos
          .add(stationForward.multiplyScalar(navigationArgs.cameraDistance * direction))
          .add(stationRight.multiplyScalar(navigationArgs.cameraOffset))
          .add(stationUp.multiplyScalar(navigationArgs.cameraHeight));

        // Look at position is fixed at station position
        lookAtPosition = stationPos;
        break;
    }

    // Update station chainage
    const stationChainage =
      this.stationPositions.xAxis.start +
      this.stationPositions.values[navigationArgs.stationIndex][0];
    this.formGroup.controls.chainage.setValue(round(stationChainage, 0), {
      emitEvent: false,
    });
    // console.log(`Station changed: ${navigationArgs.stationIndex}-${stationChainage}`, {
    //   camera: this.selectedCamera.mode,
    //   navigationArgs,
    //   stationChainage,
    //   cameraPosition,
    //   lookAtPosition: lookAtPosition,
    // });

    // Update navigation args
    this.saveNavigationArgs({
      cameraHeight: navigationArgs.cameraHeight,
      cameraDistance: navigationArgs.cameraDistance,
      cameraOffset: navigationArgs.cameraOffset,
      chainage: stationChainage,
      reverse: navigationArgs.reverse,
    });

    // Update camera if required
    if (cameraPosition && lookAtPosition) {
      await this.updateCamera(
        cameraPosition,
        lookAtPosition,
        this.initialized ? undefined : this.navigationArgs?.yawOffset, // Use offset only on first update
        this.initialized ? undefined : this.navigationArgs?.pitchOffset, // Use offset only on first update
      );
    }
  }

  private saveNavigationArgs(navArgs?: Partial<NavigationArgs>): void {
    // Ensure required values are set
    if (isNil(this.alignment)) {
      return;
    }

    // Patch navigation args
    this.navigationArgs = {
      ...this.navigationArgs,
      ...navArgs,
    } as NavigationArgs;

    this.navigationService.updateNavigationArgs(this.alignment.id, this.navigationArgs);
    // console.log('Saved navigation args', this.navigationArgs);
  }

  private async updateCamera(
    position: Vector3,
    lookAt: Vector3,
    yawOffset?: number,
    pitchOffset?: number,
  ): Promise<void> {
    // Ensure correct camera mode
    await this.workspace.api.viewer.setCameraMode(this.selectedCamera.connectCameraMode);

    // Calculate yaw and pitch angles
    const lookAtDir = lookAt.subtract(position);
    let yaw = Math.atan2(lookAtDir.y, lookAtDir.x);
    let pitch = Math.atan2(
      lookAtDir.z,
      Math.sqrt(lookAtDir.x * lookAtDir.x + lookAtDir.y * lookAtDir.y),
    );

    // Adjust for differences between Connect and model coordinates
    yaw = yaw - degToRad(90);
    pitch = pitch + degToRad(90);

    // Get current camera to update
    const camera = await this.workspace.api.viewer.getCamera();

    // Ensure perspective camera projection
    // Note: we need to do in a seperate call to ensure perspective is changed before adjusting positions,
    // else we are unable to check position changes
    if (camera.projectionType === 'ortho') {
      await this.workspace.api.viewer.setCamera(
        { ...camera, projectionType: 'perspective' },
        { animationTime: 1 },
      );
    }

    // Update camera (with look-at offsets if any)
    camera.position = position;
    camera.yaw = yaw + (this.lastCameraUpdate?.yawOffset ?? yawOffset ?? 0);
    camera.pitch = pitch + (this.lastCameraUpdate?.pitchOffset ?? pitchOffset ?? 0);
    camera.projectionType = 'perspective';

    this.lastCameraUpdate = {
      position,
      lookAt,
      yaw,
      pitch,
      yawOffset: this.lastCameraUpdate?.yawOffset ?? yawOffset,
      pitchOffset: this.lastCameraUpdate?.pitchOffset ?? pitchOffset,
      station: this.formGroup.value.stationIndex ?? 0,
    };
    await this.workspace.api.viewer.setCamera(camera, { animationTime: 1 });

    // Update look-at marker
    this.workspace.setLookAtMarker(lookAt);
  }

  private hasCameraPositionChanged(camera: Camera): boolean {
    // Ensure required values are set
    if (
      isNil(this.lastCameraUpdate) ||
      isNil(this.lastCameraUpdate.position) ||
      isNil(camera.position)
    ) {
      return false;
    }

    // Check if camera postion was changed externally (e.g. in viewer)
    const prevPosition = Vector3.fromCoordinate(this.lastCameraUpdate.position);
    const curPosition = Vector3.fromCoordinate(camera.position);
    return !prevPosition.match(curPosition, 1.5);
  }

  private hasCameraLookAtChanged(camera: Camera): boolean {
    // Ensure required values are set
    if (!this.formGroup.value.lockLookAt || isNil(this.lastCameraUpdate) || isNil(camera.lookAt)) {
      return false;
    }

    // Check if camera look-at changed externally (e.g. in viewer)
    const decimals = 2;
    const hasChanged =
      round(camera.yaw ?? 0, decimals) !== round(this.lastCameraUpdate.yaw, decimals) &&
      round(camera.pitch ?? 0, decimals) !== round(this.lastCameraUpdate.pitch, decimals);
    return hasChanged;
  }

  private async updateOffsetsFromCamera(): Promise<void> {
    // Get current camera details
    const camera = await this.workspace.api.viewer.getCamera();

    // Ensure required values are set
    if (isNil(this.stationPositions) || isNil(this.lastCameraUpdate) || isNil(camera.position)) {
      return;
    }

    // Get camera direction
    const lookAtPos = Vector3.fromCoordinate(this.lastCameraUpdate.lookAt);
    const cameraPos = Vector3.fromCoordinate(camera.position);
    const cameraDir = lookAtPos.subtract(cameraPos);

    // Caculation camera offsets in relation to station directions
    const stationDirections = this.stationPositions.directions[this.lastCameraUpdate.station];
    const stationForward = Vector3.fromCoordinate(stationDirections.forward);
    const stationUp = Vector3.fromCoordinate(stationDirections.up);
    const stationRight = Vector3.fromCoordinate(stationDirections.right);
    const dl = cameraDir.dot(stationRight);
    const ds = cameraDir.dot(stationForward);
    const du = cameraDir.dot(stationUp);
    // const cameraDist = Math.sqrt(dl * dl + ds * ds + du * du);

    // Update with new offsets, taking direction into account
    const direction = this.formGroup.value.reverse ? -1 : 1;
    const decimals = 2;
    this.formGroup.patchValue({
      cameraDistance: round(ds * direction, decimals),
      cameraHeight: round(du * -1, decimals),
      cameraOffset: round(dl * -1, decimals),
      lockLookAt: false,
    });
  }

  private async updateLookAtOffsetsFromCamera(lockLookAt: boolean): Promise<void> {
    if (lockLookAt && isDefined(this.lastCameraUpdate)) {
      const camera = await this.workspace.api.viewer.getCamera();
      const cameraYaw = camera.yaw ?? 0;
      const cameraPitch = camera.pitch ?? 0;
      this.lastCameraUpdate.yawOffset = cameraYaw - this.lastCameraUpdate.yaw;
      this.lastCameraUpdate.pitchOffset = cameraPitch - this.lastCameraUpdate.pitch;
    } else if (isDefined(this.lastCameraUpdate)) {
      this.lastCameraUpdate.yawOffset = undefined;
      this.lastCameraUpdate.pitchOffset = undefined;
    }

    this.saveNavigationArgs({
      lockLookAt: lockLookAt,
      yawOffset: this.lastCameraUpdate?.yawOffset,
      pitchOffset: this.lastCameraUpdate?.pitchOffset,
    });
  }

  public updateChainage(event: Event): void {
    const input = event.target as HTMLInputElement;
    const chainage = coerceNumberProperty(input.value);
    if (isDefined(chainage)) {
      this.formGroup.patchValue({ chainage: chainage });
    }
  }

  private async selectCamera(camera: AlignmentCamera): Promise<void> {
    // Apply selected camera mode
    this.selectedCamera = camera;
    this.saveNavigationArgs({ cameraMode: this.selectedCamera.mode });
    this.workspace.api.viewer.setCameraMode(this.selectedCamera.connectCameraMode);

    switch (this.selectedCamera) {
      case AlignmentCameras.Trolley:
        // Trolley-view, reset to last used values
        this.formGroup.patchValue({
          cameraHeight: defaultNavigationArgs.cameraHeight,
          cameraOffset: defaultNavigationArgs.cameraOffset,
          cameraDistance: defaultNavigationArgs.cameraDistance,
          lockLookAt: defaultNavigationArgs.lockLookAt,
          reverse: defaultNavigationArgs.reverse,
        });
        break;

      case AlignmentCameras.Attached:
        // Attached-view, calculate offsets from current camera position
        await this.updateOffsetsFromCamera();
        break;

      case AlignmentCameras.Free:
        // Free-view, do not update camera
        break;

      default:
        throw new Error(`Camera not implemented: ${camera}`);
    }
  }
}
