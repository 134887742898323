import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_TABS_CONFIG, MatTabsConfig } from '@angular/material/tabs';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { appRoutes } from './app.routes';
import { tokenInterceptor } from './interceptors';
import { WINDOW, windowProvider } from './services/window.provider';
import {
  AlignmentState,
  AppService,
  AppState,
  AuthState,
  DisplaySettingState,
  NavigationState,
  SourceFileState,
} from './state';

const tabConfig: MatTabsConfig = {
  animationDuration: '0',
  preserveContent: true,
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideRouter(appRoutes),
    provideHttpClient(withInterceptors([tokenInterceptor])),
    importProvidersFrom(
      NgxsModule.forRoot(
        [
          AppState,
          AuthState,
          AlignmentState,
          SourceFileState,
          DisplaySettingState,
          NavigationState,
        ],
        {
          selectorOptions: {
            // These Selector Settings are recommended in preparation for NGXS v4
            // (See https://www.ngxs.io/concepts/select#joining-selectors for their effects)
            suppressErrors: false,
            injectContainerState: false,
          },
        },
      ),
      NgxsStoragePluginModule.forRoot({
        key: [AuthState],
      }),
      MatDialogModule,
    ),
    windowProvider,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [AppService, WINDOW],
      multi: true,
    },
    {
      provide: MAT_TABS_CONFIG,
      useValue: tabConfig,
    },
  ],
};

function initializeAppFactory(appService: AppService, window: Window) {
  return () => appService.initialize(window);
}
